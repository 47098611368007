<template>
    <form @submit.prevent="updateLog" class="grid grid-cols-1 gap-4 md:grid-cols-2">
      <div>
        <label class="block mb-1" for="name">{{
          $t("labels.customer_name")
        }}{{ log.id }}</label>
        <InputText
          v-model="log.customer_name"
          name="name"
          placeholder="Jhon Doe"
          class="w-full"
        />
        <p v-if="errors.customer_name" class="text-label">
          {{ errors.customer_name[0] }}
        </p>
      </div>
      <div>
        <label class="block mb-1 text-label" for="product_id">Product</label>
        <Dropdown
          class="w-full"
          v-model="log.product_id"
          :options="props.products"
          option-label="name"
          option-value="id"
          optionLabel="name"
          filter
          required
        />
        <p v-if="errors.product_id" class="text-label">
          {{ errors.product_id[0] }}
        </p>
      </div>
      <div>
        <label class="block mb-1 text-label" for="delivery_status">Delivery Status</label>
        <Dropdown
          class="w-full"
          v-model="log.delivery_status"
          :options="['request' , 'arrived']"
          required
        />
        <p v-if="errors.delivery_status" class="text-label">
          {{ errors.delivery_status[0] }}
        </p>
      </div>
      <div v-if="log.delivery_status == 'arrived'">
        <label class="block mb-1 text-label" for="office_arrival_date">{{
          $t("labels.office_arrival_date")
        }}</label>
        <Calendar
          @update:modelValue="convertDate($event)"
          v-model="dates.office_arrival_date"
          showButtonBar
          required
          placeholder="01-01-2024"
          class="w-full"
        />
        <p v-if="errors.office_arrival_date" class="text-label">
          {{ errors.office_arrival_date[0] }}
        </p>
      </div>
      <div>
        <label class="block mb-1 text-label" for="iccid">{{
          $t("labels.iccid")
        }}</label>
        <InputText v-model="log.iccid" name="iccid" required class="w-full" />
        <p v-if="errors.iccid" class="text-label">
          {{ errors.iccid[0] }}
        </p>
      </div>
      <div>
        <label class="block mb-1 text-label" for="tel_no">{{
          $t("labels.tel_no")
        }}</label>
        <InputText v-model="log.tel_no" name="tel_no" required class="w-full" />
        <p v-if="errors.tel_no" class="text-label">
          {{ errors.tel_no[0] }}
        </p>
      </div>
      <div>
        <label class="block mb-1 text-label" for="return_method">{{
          $t("labels.return_method")
        }}</label>
        <Dropdown
          class="w-full"
          required
          v-model="log.return_method"
          :options="['delivery', 'office']"
        />
        <p v-if="errors.return_method" class="text-label">
          {{ errors.return_method[0] }}
        </p>
      </div>
      <div
        class="flex items-center gap-2 px-4 py-[0.4rem] mt-[1.55rem] rounded-lg h-fit"
        style="border: 1px #94a3b8 solid"
      >
        <InputSwitch
          class="mt-1"
          v-model="log.change_instock_status"
          name="change_instock_status"
        />
        <label class="block text-label" for="change_instock_status">{{
          $t("labels.change_instock_status")
        }}</label>
        <p v-if="errors.change_instock_status" class="text-label">
          ({{ errors.change_instock_status[0] }})
        </p>
      </div>
      <div class="md:col-span-2">
        <label class="block mb-1" for="remark">{{
          $t("labels.remark")
        }}</label>
        <InputText
          v-model="log.remark"
          name="remark"
          placeholder="Jhon Doe"
          class="w-full"
        />
        <p v-if="errors.remark" class="text-label">
          {{ errors.remark[0] }}
        </p>
      </div>
      <div class="flex justify-end md:col-span-2">
        <Button
          type="submit"
          size="small"
          class="px-6 py-3 text-white border-0 bg-secondary"
        >
          {{ $t("actions.update") }}
        </Button>
      </div>
    </form>
  </template>
  
  <script setup>
  import InputText from "primevue/inputtext";
  import Calendar from "primevue/calendar";
  import Dropdown from "primevue/dropdown";
  import InputSwitch from "primevue/inputswitch";
  import Button from "primevue/button";
  import ApiService from '@/services/ApiService'
  import { toast } from "vue3-toastify";
  
  import { ref , defineProps, onMounted } from "vue";

  const props = defineProps({
    log : Object,
    products : Array
  })
  
  const log = ref({
    id : "",
    delivery_status : "",
    office_arrival_date: "",
    iccid: "",
    tel_no: "",
    remark: "",
    change_instock_status: false,
    return_method: "",
    customer_name: "",
    product_id : ""
  });
  
  const errors = ref({});
  
  const dates = ref({
    office_arrival_date: "",
  });
  
  const convertDate = (dateString) => {
    if (dateString == null) {
      return;
    }
    const myanmarDate = new Date(dateString);
    const year = myanmarDate.getFullYear();
    const month = myanmarDate.getMonth() + 1;
    const day = myanmarDate.getDate();
    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
    log.value.office_arrival_date = formattedDate;
  };
  
  const updateLog = () => {
      const id = toast.loading("Updating return log, please wait . . .");
      ApiService.put(`return-logs/${log.value.id}` , log.value).then(() => {
          toast.update(id, {
          render: "Updated return log.",
          autoClose: true,
          closeOnClick: true,
          closeButton: true,
          type: "success",
          isLoading: false,
        });
        window.location.reload();
      }).catch((err) => {
          errors.value = err.response?.data?.errors
          toast.update(id, {
          render: "Error updating return log.",
          autoClose: true,
          closeOnClick: true,
          closeButton: true,
          type: "error",
          isLoading: false,
        });
      })
  }

  onMounted(() => {
    log.value = {
        ...props.log,
        change_instock_status : !!props.log.change_instock_status
    }
    dates.value.office_arrival_date = props.log.office_arrival_date ? new Date(props.log.office_arrival_date) : new Date();
  })
  
  </script>
  
  <style scoped>
  </style>