<template>
  <div class="p-1">
    <LoadingIndi v-if="loading" />
    <DataTable
      v-else
      v-model:expandedRows="expandedRows"
      :value="products"
      dataKey="product_type_id"
    >
      <template #header>
        <div class="flex items-center justify-between">
          <h1 class="text-2xl font-semibold">
            {{ $t("titles.product_records") }}
          </h1>
          <div class="flex justify-end gap-1 h-fit">
            <Button
              @click="expandAll"
              v-tooltip.bottom="'Expand all rows'"
              class="flex items-center gap-1 text-success"
            >
              <span style="font-size: 1.2rem" class="material-icons-round"
                >add</span
              >
              <span>Expand All</span>
            </Button>
            <Button
              @click="collapseAll"
              v-tooltip.bottoom="'Collapse all rows'"
              class="flex items-center gap-1 text-success"
            >
              <span style="font-size: 1.2rem" class="material-icons-round"
                >remove</span
              >
              <span>Collapse All</span>
            </Button>
          </div>
        </div>
      </template>
      <Column expander style="width: 5rem; background-color: #f1f5f9" />
      <Column
        style="background-color: #f1f5f9"
        field="productName"
        :header="$t('labels.product')"
      ></Column>
      <Column
        style="background-color: #f1f5f9"
        field="typeName"
        :header="$t('labels.product_type')"
      ></Column>
      <Column
        style="background-color: #f1f5f9"
        field="quantity"
        :header="$t('labels.quantity')"
      ></Column>
      <Column
        style="background-color: #f1f5f9"
        field="amount"
        :header="$t('labels.amount')"
      ></Column>
      <Column
        style="background-color: #f1f5f9"
        field="need_details"
        :header="$t('labels.need_details')"
      >
        <template #body="slotProps">
          <p>
            <span v-if="slotProps.data.need_details" class="text-success"
              >Yes</span
            >
            <span v-else class="text-label">No</span>
          </p>
        </template>
      </Column>
      <template #expansion="slotProps">
        <div
          v-if="slotProps.data.records && slotProps.data.records.length"
          class="p-3 shadow"
        >
          <div class="flex items-center justify-between">
            <h5 class="mb-1 text-lg font-semibold text-primary">
              {{ $t("titles.product_records") }} -
              {{ slotProps.data.typeName }} ({{ $t("labels.quantity") }} -
              {{ slotProps.data.quantity }})
            </h5>
            <Button
              v-tooltip.left="'Edit all records for product'"
              class="flex items-center gap-1 px-4 py-2 text-white rounded bg-primary"
              @click="editAllRecords(slotProps.data)"
            >
              <span style="font-size: 1.2rem" class="material-icons-round"
                >edit</span
              >
              <span>Edit Records</span>
            </Button>
          </div>
          <DataTable :value="slotProps.data.records">
            <Column
              class="min-w-[80px]"
              field="iccid"
              :header="$t('labels.iccid')"
            ></Column>
            <Column
              class="min-w-[80px]"
              field="tel_no"
              :header="$t('labels.tel_no')"
            ></Column>
            <Column
              class="min-w-[80px]"
              field="user_assign"
              :header="$t('labels.user_assign')"
            >
              <template #body="slotProps">
                <p>
                  <span
                    v-if="slotProps.data.user_assign"
                    class="font-semibold text-success"
                    >Yes</span
                  >
                  <span v-else class="font-semibold text-label">No</span>
                </p>
              </template>
            </Column>
            <Column field="remark" :header="$t('labels.remark')">
              <template #body="slotProps">
                <p class="max-w-[400px]">{{ slotProps.data.remark }}</p>
              </template>
            </Column>
            <Column field="action" :header="$t('labels.actions')">
              <template #body="slotProps">
                <Button
                  @click="editRecord(slotProps.data)"
                  class="flex items-center justify-center p-2 text-sm text-white rounded-full bg-secondary"
                >
                  <span style="font-size: 1.2rem" class="material-icons-round"
                    >edit</span
                  >
                </Button>
              </template>
            </Column>
          </DataTable>
        </div>
        <div
          v-else
          class="flex flex-col items-center justify-center gap-4 p-6 shadow text-label"
        >
          <p>{{ $t("labels.no_record_message") }}</p>
          <Button
            @click="selectProduct(slotProps.data)"
            class="px-6 py-3 text-white border-0 bg-secondary"
            size="small"
            >{{ $t("labels.create_records_for")
            }}{{ slotProps.data.typeName }}</Button
          >
        </div>
      </template>
    </DataTable>

    <!-- Create Records -->
    <Dialog
      v-model:visible="selectedProduct.show"
      modal
      :header="`${$t('labels.create_records_for')} ${
        selectedProduct.typeName
      } (${$t('labels.quantity')} - ${selectedProduct.quantity})`"
      :style="{ width: '60rem' }"
    >
      <form @submit.prevent="createRecordsForProduct" class="p-2">
        <div class="flex items-center gap-1 mb-2">
          <p>{{ $t("labels.wrong_quantity") }}</p>
          <span
            @click="
              selectProductToChangeQuantity(
                selectedProduct.product_type_id,
                selectedProduct.quantity
              )
            "
            class="underline cursor-pointer text-secondary"
            >{{ $t("labels.change_quantity") }}</span
          >
        </div>
        <div
          class="grid grid-cols-2 gap-3 p-3 mb-4 rounded-lg"
          style="border: 1px solid #3a3f93"
          v-for="(rec, i) in selectedProduct.records"
          :key="i"
        >
          <div class="col-span-2 text-lg font-semibold">{{ i + 1 }}.</div>
          <div>
            <label class="block text-label" for="quantity">{{
              $t("labels.iccid")
            }}</label>
            <InputText
              required
              v-model="selectedProduct.records[i]['iccid']"
              class="w-full mt-1"
            />
            <p v-if="errors[`records.${i}.iccid`]" class="text-label">
              {{ errors[`records.${i}.iccid`][0] }}
            </p>
          </div>
          <div>
            <label class="block text-label" for="quantity">{{
              $t("labels.tel_no")
            }}</label>
            <InputText
              required
              v-model="selectedProduct.records[i]['tel_no']"
              class="w-full mt-1"
            />
            <p v-if="errors[`records.${i}.tel_no`]" class="text-label">
              {{ errors[`records.${i}.tel_no`][0] }}
            </p>
          </div>
          <div>
            <label class="block" for="quantity">{{
              $t("labels.remark")
            }}</label>
            <InputText
              v-model="selectedProduct.records[i]['remark']"
              class="w-full mt-1"
            />
          </div>
          <div
            class="flex items-center gap-2 px-4 py-[0.4rem] mt-[1.55rem] rounded-lg h-fit"
            style="border: 1px #94a3b8 solid"
          >
            <InputSwitch
              class="mt-1"
              v-model="selectedProduct.records[i]['user_assign']"
            />
            <label class="block" for="user_assign">{{
              $t("labels.user_assign")
            }}</label>
          </div>
        </div>
        <div class="flex justify-end mt-6">
          <Button
            type="submit"
            size="small"
            class="px-6 py-3 text-white border-0 bg-secondary"
          >
            {{ $t("labels.create_records") }}
          </Button>
        </div>
      </form>
    </Dialog>

    <!-- Edit all records -->
    <Dialog
      v-model:visible="editAll.show"
      modal
      :header="`${$t('labels.create_records_for')} ${editAll.typeName} (${$t(
        'labels.quantity'
      )} - ${editAll.quantity})`"
      :style="{ width: '60rem' }"
    >
      <form @submit.prevent="updateAllRecord" class="p-2">
        <div
          class="grid grid-cols-2 gap-3 p-3 mb-4 rounded-lg"
          style="border: 1px solid #3a3f93"
          v-for="(rec, i) in editAll.records"
          :key="i"
        >
          <div class="col-span-2 text-lg font-semibold">{{ i + 1 }}.</div>
          <div>
            <label class="block text-label" for="quantity">{{
              $t("labels.iccid")
            }}</label>
            <InputText
              required
              v-model="editAll.records[i]['iccid']"
              class="w-full mt-1"
            />
            <p v-if="errors[`records.${i}.iccid`]" class="text-label">
              {{ errors[`records.${i}.iccid`][0] }}
            </p>
          </div>
          <div>
            <label class="block text-label" for="quantity">{{
              $t("labels.tel_no")
            }}</label>
            <InputText
              required
              v-model="editAll.records[i]['tel_no']"
              class="w-full mt-1"
            />
            <p v-if="errors[`records.${i}.tel_no`]" class="text-label">
              {{ errors[`records.${i}.tel_no`][0] }}
            </p>
          </div>
          <div>
            <label class="block" for="quantity">{{
              $t("labels.remark")
            }}</label>
            <InputText
              v-model="editAll.records[i]['remark']"
              class="w-full mt-1"
            />
          </div>
          <div
            class="flex items-center gap-2 px-4 py-[0.4rem] mt-[1.55rem] rounded-lg h-fit"
            style="border: 1px #94a3b8 solid"
          >
            <InputSwitch
              class="mt-1"
              v-model="editAll.records[i]['user_assign']"
            />
            <label class="block" for="user_assign">{{
              $t("labels.user_assign")
            }}</label>
          </div>
        </div>
        <div class="flex justify-end mt-6">
          <Button
            type="submit"
            size="small"
            class="px-6 py-3 text-white border-0 bg-secondary"
          >
            {{ $t("labels.create_records") }}
          </Button>
        </div>
      </form>
    </Dialog>

    <!-- Edit quantity -->
    <Dialog
      v-model:visible="changedQuantity.show"
      modal
      :header="`Change Quantity for ${selectedProduct.typeName} (Quantity - ${selectedProduct.quantity})`"
      :style="{ width: '60rem' }"
    >
      <form @submit.prevent="changeQuantity" class="p-2">
        <div>
          <label class="block text-label" for="quantity">{{
            $t("labels.quantity")
          }}</label>
          <InputNumber
            required
            v-model="changedQuantity.quantity"
            class="w-full mt-1"
          />
          <p v-if="errors.quantity" class="text-label">{{ errors.quantity }}</p>
        </div>

        <div class="flex justify-end mt-6">
          <Button
            type="submit"
            size="small"
            class="px-6 py-3 text-white border-0 bg-secondary"
          >
            {{ $t("actions.update") }}
          </Button>
        </div>
      </form>
    </Dialog>

    <!-- Edit record -->
    <Dialog
      v-model:visible="recordEdit.show"
      modal
      :header="`Edit Record`"
      :style="{ width: '60rem' }"
    >
      <form @submit.prevent="updateRecord" class="grid grid-cols-2 gap-4 p-2">
        <div>
          <label class="block text-label" for="iccid">{{
            $t("labels.iccid")
          }}</label>
          <InputText
            required
            v-model="recordEdit.record.iccid"
            class="w-full mt-1"
          />
          <p v-if="errors.iccid" class="text-label">
            {{ errors.iccid[0] }}
          </p>
        </div>
        <div>
          <label class="block text-label" for="tel_no">{{
            $t("labels.tel_no")
          }}</label>
          <InputText
            required
            v-model="recordEdit.record.tel_no"
            class="w-full mt-1"
          />
          <p v-if="errors.tel_no" class="text-label">
            {{ errors.tel_no[0] }}
          </p>
        </div>
        <div>
          <label class="block" for="remark">{{ $t("labels.remark") }}</label>
          <InputText v-model="recordEdit.record.remark" class="w-full mt-1" />
        </div>
        <div
          class="flex items-center gap-2 px-4 py-[0.4rem] mt-[1.55rem] rounded-lg h-fit"
          style="border: 1px #94a3b8 solid"
        >
          <InputSwitch class="mt-1" v-model="recordEdit.record.user_assign" />
          <label class="block" for="user_assign">{{
            $t("labels.user_assign")
          }}</label>
        </div>
        <div class="flex items-center justify-end col-span-2 gap-4 mt-6">
          <span
            @click="recordEdit.show = false"
            class="cursor-pointer text-secondary"
          >
            {{ $t("actions.cancel") }}
          </span>
          <Button
            size="small"
            type="submit"
            class="px-6 py-3 text-white border-0 bg-secondary"
          >
            {{ $t("actions.update") }}
          </Button>
        </div>
      </form>
    </Dialog>

    <!-- Order details -->
    <Dialog
      v-model:visible="showOrderDetails"
      modal
      :header="`Order Details`"
      :style="{ width: '60rem' }"
    >
      <div class="grid grid-cols-2 gap-4">
        <div>
          <h5 class="font-semibold">{{ $t("labels.name") }}</h5>
          <p class="mt-1 ml-2">{{ order.name }}</p>
        </div>
        <div>
          <h5 class="font-semibold">{{ $t("labels.phone_no") }}</h5>
          <p class="mt-1 ml-2">{{ order.phone_no }}</p>
        </div>
        <div>
          <h5 class="font-semibold">{{ $t("labels.postal_code") }}</h5>
          <p class="mt-1 ml-2">{{ order.postal_code }}</p>
        </div>
        <div>
          <h5 class="font-semibold">{{ $t("labels.address") }}</h5>
          <p class="mt-1 ml-2">{{ order.address }}</p>
        </div>
        <div>
          <h5 class="font-semibold">{{ $t("labels.activate_on") }}</h5>
          <p class="mt-1 ml-2">{{ order.activate_on }}</p>
        </div>
        <div>
          <h5 class="font-semibold">{{ $t("labels.deli_status") }}</h5>
          <p class="mt-1 ml-2">{{ order.delivery_status }}</p>
        </div>
        <div>
          <h5 class="font-semibold">{{ $t("labels.payment_status") }}</h5>
          <p class="mt-1 ml-2">{{ order.payment_status }}</p>
        </div>
        <div class="col-span-2">
          <p class="font-semibold">
            - Created at
            <span class="text-secondary">{{
              order.created_at?.slice(0, 10)
            }}</span>
            by <span class="text-secondary">{{ order.user.name }}</span>
          </p>
        </div>
      </div>
    </Dialog>
  </div>
</template>
  
  <script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";
import ApiService from "@/services/ApiService";
//primevue component imports
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";

import LoadingIndi from "@/components/LoadingIndi.vue";

const route = useRoute();
const order = ref({});
const products = ref([]);
const expandedRows = ref({});
const loading = ref(false);
const showOrderDetails = ref(false);
const selectedProduct = ref({
  product: {},
  records: [],
  show: false,
});
const editAll = ref({
  product: {},
  records: [],
  show: false,
});
const errors = ref({});
const changedQuantity = ref({
  show: false,
  typeId: 0,
  quantity: 0,
});

const recordEdit = ref({
  show: false,
  record: {
    id: "",
    iccid: "",
    tel_no: "",
    remark: "",
    user_assign: false,
  },
});

const expandAll = () => {
  products.value.forEach((product) => {
    expandedRows.value[product.product_type_id] = true;
  });
};

const collapseAll = () => {
  expandedRows.value = {};
};

const selectProduct = (product) => {
  window.sessionStorage.setItem("active_product", product.product_type_id);
  selectedProduct.value = { ...product, records: [], show: true };
  for (let i = 0; i < product.quantity; i++) {
    let rec = {
      iccid: "",
      tel_no: "",
      remark: "",
      user_assign: false,
      order_id: route.params.id,
      order_product_type_id: product.product_type_id,
      product_type_id: product.product_type_id,
    };
    selectedProduct.value.records.push(rec);
  }
};

const createRecordsForProduct = () => {
  console.log(selectedProduct.value);
  const id = toast.loading("Creating records, please wait . . .");
  ApiService.post("/records", selectedProduct.value)
    .then(() => {
      toast.update(id, {
        render: "Created records.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      window.location.reload();
    })
    .catch((err) => {
      toast.update(id, {
        render: "Error creating records.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
      errors.value = err.response?.data?.errors;
    });
};

const selectProductToChangeQuantity = (typeId, quantity) => {
  changedQuantity.value.show = true;
  changedQuantity.value.typeId = typeId;
  changedQuantity.value.quantity = quantity;
};

const changeQuantity = () => {
  if (!changedQuantity.value.quantity || changedQuantity.value.quantity < 0) {
    errors.value.quantity = "Please fill a valid number for quantity.";
    return;
  }
  const id = toast.loading("Updating quantity, please wait . . .");
  ApiService.post(
    `/records/${route.params.id}/${changedQuantity.value.typeId}/change-quantity`,
    changedQuantity.value
  )
    .then(() => {
      toast.update(id, {
        render: "Updated quantity.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      window.location.reload();
    })
    .catch(() => {
      toast.update(id, {
        render: "Error updating quantity.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
    });
};

const editRecord = (record) => {
  window.sessionStorage.setItem("active_product", record.product_type_id);
  recordEdit.value.show = true;
  recordEdit.value.record = { ...record, user_assign: !!record.user_assign };
  console.log("am here - ", recordEdit.value.record);
};

const editAllRecords = (product) => {
  console.log(product);
  editAll.value.product = product;
  editAll.value.records = product.records.map((rec) => {
    return {
      ...rec,
      user_assign : !!rec.user_assign,
      order_id: route.params.id,
      order_product_type_id: product.product_type_id,
      product_type_id: product.product_type_id,
      type : 'update'
    }
  });
  const needLength = product.quantity - editAll.value?.records?.length;
  console.log('need - ' , needLength)
  for (let i = 0; i < needLength; i++) {
    let rec = {
      iccid: "",
      tel_no: "",
      remark: "",
      user_assign: false,
      order_id: route.params.id,
      order_product_type_id: product.product_type_id,
      product_type_id: product.product_type_id,
      type : 'add'
    };
    editAll.value.records.push(rec)
  }
  editAll.value.show = true;
  console.log(editAll.value.records)
};

const updateAllRecord = () => {
  const id = toast.loading("Updating records, please wait . . .");
  ApiService.put(
    `/records/${route.params.id}/update-all`,
    editAll.value
  )
    .then(() => {
      toast.update(id, {
        render: "Updated records.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      window.location.reload();
    })
    .catch((err) => {
      toast.update(id, {
        render: "Error updating records.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
      errors.value = err.response.data && err.response.data.errors ? err.response?.data?.errors : {};
    });
};

const updateRecord = () => {
  const id = toast.loading("Updating record, please wait . . .");
  ApiService.put(
    `/records/${recordEdit.value.record.id}`,
    recordEdit.value.record
  )
    .then(() => {
      toast.update(id, {
        render: "Updated record.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      window.location.reload();
    })
    .catch((err) => {
      toast.update(id, {
        render: "Error updating record.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
      errors.value = err.response?.data?.errors;
    });
};

onMounted(() => {
  loading.value = true;
  ApiService.get(`records/${route.params.id}/products`)
    .then((res) => {
      const data = res.data;
      order.value = {
        name: data.name,
        phone_no: data.phone_no,
        activate_on: data.activate_on,
        postal_code: data.postal_code,
        address: data.address,
        delivery_status: "shipped",
        payment_status: "unpaid",
        created_at: data.created_at,
        user: data.user,
      };
      data.product_types.forEach((t) => {
        let obj = {
          product_type_id: t.pivot.product_type_id,
          product_id: t.product.id,
          productName: t.product.name,
          typeName: t.name,
          amount: t.pivot.amount,
          quantity: t.pivot.quantity,
          iccid: t.pivot.iccid,
          remark: t.pivot.remark,
          records: t.records,
          need_details: t.pivot.need_details,
        };
        products.value.push(obj);
      });
      if (sessionStorage.getItem("active_product")) {
        expandedRows.value = {
          [sessionStorage.getItem("active_product")]: true,
        };
      } else {
        expandedRows.value = {
          [products.value[0]["product_type_id"]]: true,
        };
      }
      loading.value = false;
    })
    .catch(() => {
      loading.value = false;
      toast.error("Error fetching data.");
    });
});
</script>
  
  <style scoped></style>
  