<template>
  <div>
    <div>
      <input @change="uploadFiles" type="file" class="hidden" id="fileupload" />
      <button
        @click="focusAdditionalFile"
        class="py-3 text-white rounded outline-none lg:w-1/3 bg-secondary ring-0"
      >
        {{ $t("actions.upload_files") }}
      </button>
      <h2></h2>
      <div v-if="filesPreview?.length" style="columns: 120px;" class="my-4">
        <img
          v-for="file in filesPreview"
          :key="file.id"
          class="w-full m-2"
          :src="file.url"
          alt=""
        />
      </div>
      <div
        class="flex justify-center pb-2"
        style="border-bottom: 1px solid gray"
        v-if="filesPreview?.length && formData.has('files[]')"
      >
        <button
          :disabled="filesLoading"
          @click="uploadAllFiles"
          class="px-6 py-3 text-white rounded outline-none h-fit bg-success rign-0"
        >
          <span v-if="filesLoading">Loading</span>
          <span v-else>{{ $t("actions.upload_all") }}</span>
        </button>
      </div>
      <div
        v-if="additionalFiles?.length"
        style="columns: 250px"
        class="gap-3 my-4"
      >
        <img
          v-for="file in additionalFiles"
          :key="file.id"
          class="w-full m-2"
          :src="file.url"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { toast } from "vue3-toastify";

const props = defineProps({
  id: Number,
});

const filesPreview = ref([]);
const additionalFiles = ref([]);
let formData = new FormData();
const filesLoading = ref(false);

const uploadFiles = (e) => {
  if (e.target.files?.length) {
    formData.append("files[]", e.target.files[0]);
    let tempUrl = URL.createObjectURL(e.target.files[0]);
    let tempId = Math.floor(Math.random() * 10);
    let tempObj = {
      id: tempId,
      url: tempUrl,
    };
    filesPreview.value.push(tempObj);
  }
};
const focusAdditionalFile = () => {
  document.getElementById("fileupload").click();
};

const uploadAllFiles = () => {
  filesLoading.value = true;
  const id = toast.loading("Uploading, please wait . . .");
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  ApiService.post(`return-logs/${props.id}/files`, formData, headers)
    .then((res) => {
      filesLoading.value = false;
      toast.update(id, {
        render: "Uploaded files.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      additionalFiles.value = res.data;
      filesPreview.value = [];
      formData.delete("files[]");
    })
    .catch(() => {
      filesLoading.value = false;
      toast.update(id, {
        render: "Error uploading files.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
    });
};

onMounted(() => {
  ApiService.get(`/return-logs/${props.id}/files`)
    .then((res) => {
      console.log(res);
      additionalFiles.value = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
});
</script>

<style scoped>
</style>