<template>
  <div>
    <!-- filters -->
    <h1 class="text-[1.35rem] text-primary font-semibold mt-8 px-3">
      {{ $t("titles.filters") }}
    </h1>
    <div class="grid grid-cols-2 gap-4 px-3 mt-2 mb-6 md:grid-cols-4">
      <div>
        <label class="block mb-1" for="id">{{ $t("labels.id") }}</label>
        <InputText
          v-model="filters.id"
          @change="filterList(currentPage)"
          name="id"
          placeholder="123"
          class="w-full"
          :class="filters.id ? 'border-success' : ''"
        />
      </div>
      <div>
        <label class="block mb-1" for="delivery_status">Delivery Status</label>
        <Dropdown
          showClear
          class="w-full"
          :class="filters.delivery_status ? 'border-success' : ''"
          v-model="filters.delivery_status"
          @change="filterList(currentPage)"
          :options="['request' , 'arrived']"
        />
      </div>
      <div>
        <label class="block mb-1" for="office_arrival_date">{{
          $t("labels.office_arrival_date")
        }}</label>
        <Calendar
          @update:modelValue="convertDate($event, 'office_arrival_date')"
          v-model="dates.created_at"
          @clear-click="clearDate('office_arrival_date')"
          showButtonBar
          placeholder="01-01-2024"
          class="w-full"
          :class="filters.created_at ? 'border-success' : ''"
        />
      </div>
      <div>
        <label class="block mb-1" for="id">{{ $t("labels.iccid") }}</label>
        <InputText
          v-model="filters.iccid"
          @change="filterList(currentPage)"
          name="iccid"
          class="w-full"
          :class="filters.iccid ? 'border-success' : ''"
        />
      </div>
      <div>
        <label class="block mb-1" for="id">{{ $t("labels.tel_no") }}</label>
        <InputText
          v-model="filters.tel_no"
          @change="filterList(currentPage)"
          name="tel_no"
          class="w-full"
          :class="filters.tel_no ? 'border-success' : ''"
        />
      </div>
      <div>
        <label class="block mb-1" for="id">{{
          $t("labels.customer_name")
        }}</label>
        <InputText
          v-model="filters.customer_name"
          @change="filterList(currentPage)"
          name="customer_name"
          class="w-full"
          :class="filters.customer_name ? 'border-success' : ''"
        />
      </div>
      <div>
        <label class="block mb-1" for="return_method">{{
          $t("labels.return_method")
        }}</label>
        <Dropdown
          showClear
          class="w-full"
          :class="filters.return_method ? 'border-success' : ''"
          v-model="filters.return_method"
          @change="filterList(currentPage)"
          :options="['delivery', 'office']"
        />
      </div>
      <div>
        <label class="block mb-1" for="id">{{ $t("labels.remark") }}</label>
        <InputText
          v-model="filters.remark"
          @change="filterList(currentPage)"
          name="remark"
          class="w-full"
          :class="filters.remark ? 'border-success' : ''"
        />
      </div>
      <div>
        <label class="block mb-1" for="product">Product</label>
        <Dropdown
          showClear
          class="w-full"
          :class="filters.product_id ? 'border-success' : ''"
          v-model="filters.product_id"
          @change="filterList(currentPage)"
          :options="products"
          option-label="name"
          option-value="id"
          optionLabel="name"
          filter
        />
      </div>
      <div
        class="flex items-center gap-2 col-span-2 w-fit px-4 py-[0.4rem] mt-[1.55rem] rounded-lg h-fit"
        style="border: 1px #94a3b8 solid"
      >
        <InputSwitch
          class="mt-1"
          v-model="filters.change_instock_status"
          @change="filterList(currentPage)"
          name="change_instock_status"
        />
        <label class="block" for="change_instock_status">{{
          $t("labels.change_instock_status")
        }}</label>
        <span
          @click="
            () => {
              filters.change_instock_status = '';
              filterList(currentPage);
            }
          "
          style="font-size: 1.4rem; color: red; cursor: pointer"
          v-tooltip.bottom="'Remove status filter'"
          class="material-icons-outlined"
          >close</span
        >
      </div>
    </div>
    <LoadingIndi v-if="loading" />
    <DataTable v-else :value="logs" tableStyle="min-width: 50rem">
      <template #header>
        <div class="flex justify-between">
          <h1 class="text-2xl font-normal">{{ $t("titles.return_logs") }}</h1>
          <Button
            :label="$t('actions.create')"
            class="px-6 py-3 text-white border-0 bg-secondary"
            size="small"
            @click="createDialog.show = true"
          />
        </div>
      </template>
      <Column field="id" :header="$t('labels.id')"></Column>
      <Column field="user.name" :header="$t('labels.created_by')"></Column>
      <Column field="product.name" header="Product"></Column>
      <Column
        field="delivery_status"
        header="Delivery Status"
      >
      <template #body="slotProps">
        <p :class="slotProps.data.delivery_status == 'arrived' ? 'text-success font-semibold capitalize' : 'font-semibold capitalize'">
          {{ slotProps.data.delivery_status }}
        </p>
      </template>
    </Column>
      <Column
        field="office_arrival_date"
        :header="$t('labels.office_arrival_date')"
      ></Column>
      <Column
        field="customer_name"
        :header="$t('labels.customer_name')"
      ></Column>
      <Column field="iccid" :header="$t('labels.iccid')"></Column>
      <Column field="tel_no" :header="$t('labels.tel_no')"></Column>
      <Column
        field="change_instock_status"
        :header="$t('labels.change_instock_status')"
      >
        <template #body="slotProps">
          <p class="font-semibold">
            <span
              v-if="slotProps.data.change_instock_status"
              class="text-success"
              >Yes</span
            >
            <span v-else class="text-label">No</span>
          </p>
        </template>
      </Column>
      <Column
        field="return_method"
        :header="$t('labels.return_method')"
      ></Column>
      <Column field="remark" :header="$t('labels.remark')"></Column>
      <Column field="custom" :header="$t('labels.actions')">
        <template #body="slotProps">
          <div class="relative flex items-center gap-3 text-white">
            <button
              @click="() => {
                logFiles.show = true;
                logFiles.id = slotProps.data.id;
              }"
              v-tooltip.bottom="'Files'"
              class="flex items-center justify-center p-2 rounded-full bg-success hover:bg-success/90"
            >
              <span style="font-size: 1.4rem" class="material-icons-outlined"
                >description</span
              >
            </button>
            <button
              @click="editLog(slotProps.data)"
              v-tooltip.bottom="'Edit log'"
              class="flex items-center justify-center p-2 rounded-full bg-secondary hover:bg-secondary/90"
            >
              <span style="font-size: 1.4rem" class="material-icons-outlined"
                >edit</span
              >
            </button>
            <button
              @click="deleteLog(slotProps.data.id)"
              v-tooltip.bottom="'Delete log'"
              class="flex items-center justify-center p-2 rounded-full bg-label hover:bg-label/90"
            >
              <span style="font-size: 1.4rem" class="material-icons-outlined"
                >delete</span
              >
            </button>
          </div>
        </template>
      </Column>
      <template #footer>
        <div
          class="flex w-full font-bold"
          :class="selectedOrders.length ? 'justify-between' : 'justify-end'"
        >
          <div class="flex items-center gap-4">
            <Dropdown
              v-model="perPage"
              :options="[10, 20, 30, 50]"
              @update:model-value="changePerPage($event)"
            />
            <Paginator
              v-if="meta.total"
              @page="onPaginate($event)"
              :rows="meta.perPage"
              v-model:first="meta.from"
              :current-page-report-template="meta.current_page"
              :totalRecords="meta.total"
            ></Paginator>
          </div>
        </div>
      </template>
    </DataTable>

    <!-- Create log -->
    <Dialog
      v-model:visible="createDialog.show"
      modal
      header="Create Return Log"
      :style="{ width: '60rem' }"
    >
      <CreateLog :products="products" />
    </Dialog>

    <!-- Edit log -->
    <Dialog
      v-model:visible="logEdit.show"
      modal
      header="Update Return Log"
      :style="{ width: '60rem' }"
    >
      <EditLog :log="logEdit.log" :products="products" />
    </Dialog>

    <!-- Log Files -->
    <Dialog
      v-model:visible="logFiles.show"
      modal
      header="Return Log Files"
    >
      <LogFiles :id="logFiles.id" />
    </Dialog>

  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import ApiService from "@/services/ApiService";

//primevue component imports
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Paginator from "primevue/paginator";
import LoadingIndi from "@/components/LoadingIndi.vue";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";

import CreateLog from "@/components/return_logs/CreateLog.vue";
import EditLog from "@/components/return_logs/EditLog.vue";
import LogFiles from "@/components/return_logs/LogFiles.vue";
import { toast } from "vue3-toastify";

const router = useRouter();
const route = useRoute();

const currentPage = ref(isNaN(+route.query.page) ? 1 : +route.query.page);
const perPage = ref(isNaN(+route.query.perPage) ? 10 : +route.query.perPage);
const meta = ref({});
const logs = ref([]);
const loading = ref(true);
const selectedOrders = ref([]);
const products = ref([]);
const filters = ref({
  id: route.query.id,
  perPage: 10,
  change_instock_status:
    route.query.change_instock_status == "1"
      ? "1"
      : route.query.change_instock_status == "0"
      ? "0"
      : "",
  delivery_status: route.query.delivery_status,
  office_arrival_date: route.query.office_arrival_date,
  customer_name: route.query.customer_name,
  iccid: route.query.iccid,
  remark: route.query.remark,
  tel_no: route.query.tel_no,
  return_method: route.query.return_method,
  product_id: route.query.product_id,
});
const dates = ref({
  office_arrival_date: "",
});

const createDialog = ref({
  show: false,
});

const logEdit = ref({
  show: false,
  log: {},
});

const logFiles = ref({show : false , id : 0});

const editLog = (log) => {
  logEdit.value.log = log;
  logEdit.value.show = true;
};

const deleteLog = (id) => {
  const tId = toast.loading("Deleting return log, please wait . . .");
  ApiService.delete(`return-logs/${id}`)
    .then(() => {
      toast.update(tId, {
        render: "Deleted return log.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      fetchData(currentPage.value);
    })
    .catch(() => {
      toast.update(tId, {
        render: "Error deleting return log.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
    });
};

const fetchData = async (page) => {
  loading.value = true;
  return ApiService.get("return-logs", {
    params: {
      ...filters.value,
      page: page,
      perPage: perPage.value,
      change_instock_status:
        filters.value.change_instock_status == "1"
          ? "1"
          : filters.value.change_instock_status == "0"
          ? "0"
          : "",
    },
  })
    .then((res) => {
      loading.value = false;
      logs.value = res.data.data;
      meta.value = res.meta;
    })
    .catch(() => {
      loading.value = false;
    });
};

const clearDate = (field) => {
  filters.value[field] = "";
  dates.value[field] = "";
  filterList();
};
const convertDate = (dateString, field) => {
  if (dateString == null) {
    return;
  }
  const myanmarDate = new Date(dateString);
  const year = myanmarDate.getFullYear();
  const month = myanmarDate.getMonth() + 1;
  const day = myanmarDate.getDate();
  if (field == "activate") {
    filters.value.activate = `${month < 10 ? "0" : ""}${month}-${year}`;
  } else {
    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
    filters.value[field] = formattedDate;
  }
  filterList();
};

const onPaginate = (e) => {
  currentPage.value = e.page + 1;
  router.push({
    name: "ReturnLogs",
    query: {
      page: currentPage.value,
      perPage: perPage.value,
      ...filterList.value,
    },
  });
  fetchData(currentPage.value);
};

const changePerPage = (e) => {
  perPage.value = e;
  router.push({
    name: "ReturnLogs",
    query: {
      page: currentPage.value,
      perPage: perPage.value,
      ...filterList.value,
    },
  });
  fetchData(currentPage.value);
};

const filterList = async () => {
  router.push({
    name: "ReturnLogs",
    query: {
      ...filters.value,
      change_instock_status:
        filters.value.change_instock_status == "1"
          ? "1"
          : filters.value.change_instock_status == "0"
          ? "0"
          : "",
      page: currentPage.value,
      perPage: perPage.value,
    },
  });
  return fetchData(currentPage.value);
};
onMounted(() => {
  filterList();
  ApiService.get('products-list').then((res) => {
    products.value = res;
  }).catch((err) => {
    console.log(err)
  })
});
</script>

<style scoped>
</style>